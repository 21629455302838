export default {

  /**
   * Exporteert een array met objecten naar CSV waarbij de eerste row de kolommen zijn
   *
   * @param array
   * @param delimiter
   * @param exportColumnNames
   */
  exportToCsv(array, delimiter = ';', exportColumnNames = []) {
    let column_names = Object.keys(array[0]);
    if (exportColumnNames.length > 0) {
      column_names = exportColumnNames;
    }

    const csvFileName = "MEDIMO-DATA-" + Date.now() + '.csv';

    let csvContent = 'sep=' + delimiter + '\r\n';
    csvContent += column_names.join(delimiter) + "\r\n";
    array.forEach(row => {
      csvContent += Object.values(row).join(delimiter) + "\r\n";
    });

    if (window.navigator.msSaveBlob) {
      //Internet Explorer 11
      window.navigator.msSaveBlob(new Blob([csvContent]), csvFileName);
    } else if (window.webkitURL != null) {
      // Chrome, Firefox
      const a = document.createElement("a");
      csvContent = encodeURIComponent(csvContent);
      a.href = "data:application/csv;charset=UTF-8," + csvContent;
      a.download = csvFileName;
      a.click();
    } else {
      //Everything Else
      window.open(csvContent);
    }
  },

};
